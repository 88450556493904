import React, { useState } from 'react'
import "./Teams.css"
import RajeshRanjanBlackTheme from "../../assets/RajeshRanjanBlackTheme.jpg"
import RajeshRanjan from "../../assets/RajeshRanjan.jpg"
import NileshAgrawalBlackTheme from "../../assets/NileshAgrawalBlackTheme.jpg"
import NileshAgrawal from "../../assets/NileshAgrawal.png"
import KomalJainBlackThme from "../../assets/KomalJainBlackThme.jpg"
import KomalJain from "../../assets/KomalJain.jpg"

import AshokBlackThemeImage from "../../assets/AshokBlackTheme.png"
import AshokImage from "../../assets/Ashok.png"
import AnindyaBlackThemeImage from "../../assets/AnindyaBlackTheme.jpg"
import AnindyaImage from "../../assets/Anindya.jpg"

import PankajJatharBlackTheme from "../../assets/PankajJatharBlackTheme.jpg"
import PankajImage from "../../assets/PankajJathar2.jpg"

import SuryakumarBlackTheme from "../../assets/SuryakumarBlackTheme.jpg"
import SuryakumarImage from "../../assets/Suryakumar.jpg"
import ArunAgrawalBlackTheme from "../../assets/ArunAgrawalBlackTheme.jpg"
import ArunAgrawal from "../../assets/ArunAgrawal.jpg"
import PrashantTandonBlackTheme from "../../assets/PrashantTandonBlackTheme.png"
import PrashantTandon from "../../assets/PrashantTandon.png"
import linkdin from "../../assets/linkedin.png"
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { MdAdd } from "react-icons/md";
import { Modal } from "react-bootstrap";
const Teams = () => {
    const [showModal, setShowModal] = useState({
        state: false,
        data: ""
    });

    const setting = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,

    };

    const founder = [
        {
            "name": "Rajesh Ranjan, CFA",
            "role": "Founder and Managing Partner",
            "imgUrlDefault": RajeshRanjanBlackTheme,
            "imgUrlHover": RajeshRanjan,
            "linkdinUrl": "https://www.linkedin.com/in/rajeshranjan/",
            "aboutUs": [
                {
                    title: `Ranjan, with an overall work experience of 25 years has a strong background in venture and private equity investments at NABVENTURES Fund, Q Investments,LP (USA), a multi-strategy hedge fund and CapAleph fund, IDFC Limited, IDFC etc.`
                },
                {
                    title: `Set up and managed NABVENTURES, the best performing fund in agtech, foodtech, rural fintech, rural tech etc`
                },
                {
                    title: `Headed the Smart Farming Group of AI4AI (Artificial Intelligence for Agriculture Innovation) of World Economic Forum (WEF).`
                },
                {
                    title: `Part of the two-member team managing IDFC’s principal investments and was an Investment Principal at the India arm of Q Investments, LP, a US based multi-strategy hedge fund. In his last stint, Ranjan was Director and head of investments at CapAleph Fund, an early stage fund focused on food and agri investments.`
                },
                // {
                //     title: `IRR at Q Investments (70%+ IRR), IDFC (40%+ IRR), NABVENTURES (47% IRR estimated) `
                // },
                {
                    title: `Some of his investments include Homeville (co-lending platform for housing), Beyond Snack (consumer brand), Jai Kisan (rural fintech), Advarisk (fintech), Vilcart (rural FMCG distribution platform), Eggoz (only pan India egg brand), Utkal Tubers, XCEL Telecom, L&T Infrastructure and Development Projects Limited, SMMS Investments, Gujarat State Petronet, National Stock Exchange, ARCIL.`
                },
                {
                    title: `Ranjan is an alumnus of St. Stephen’s College (Economics) and an MBA from Indian Institute of Management Calcutta (IIM Calcutta). He also holds Chartered Financial Analyst (CFA) charter of CFA Institute (USA) and Chartered Alternative Investment Analyst (CAIA) charter of CAIA Association.`
                }
            ],
        },
        {
            "name": "Nilesh Agrawal",
            "imgUrlDefault": NileshAgrawalBlackTheme,
            "imgUrlHover": NileshAgrawal,
            "role": "Co-founder and Operating Partner",
            "aboutUs": [
                {
                    title: `Deep experience in management and tech consulting, business analysis, strategy etc.`
                },
                {
                    title: `Working at UST Global, a digital transformation company`
                },
                {
                    title: `Previously worked at EPAM, a product engineering and digital transformation consulting company in USA`
                },
                {
                    title: `Former Lead for technology at Accenture (USA)`
                },
                {
                    title: `Growth hacker and LP in few VC funds in India.`
                },
                {
                    title: `MBA from IIM Calcutta.`
                },

            ],
        },
        // {
        //     "name": "Komal Jain",
        //     "imgUrlDefault": KomalJainBlackThme,
        //     "imgUrlHover": KomalJain,
        //     "role": "Vice President",
        //     "aboutUs": [
        //         {
        //             title: `Managed startup investments and value creation at Powerweave, a tech firm.`
        //         },
        //         {
        //             title: `Worked in the past at Acorn Fund (Centrum Group).`
        //         },
        //         {
        //             title: `Past experience in investment banking (Destimoney, Quest Profin) and 
        //             operations at Bodykraft Salon.`
        //         },


        //     ],
        // }
    ]


    const advisor = [
        {
            name: 'Ashok Nair',
            role: 'Former Managing Director, Abbott Labs India',
            imgUrlDefault: AshokBlackThemeImage,
            imgUrlHover: AshokImage,
            linkdinUrl: "https://www.linkedin.com/in/ashok-nair-0142a611/",
            aboutUs: [

                {
                    title: `Co-founder and Global Executive Director, Watch Your Health (WYH), a startup focused on building a full digital ecosystem to connect Healthcare providers to patients.`
                },
                {
                    title: `Mr. Nair’s extensive experience spans therapy areas across acute, chronic, and consumer care.`
                },
                {
                    title: `Former MD, Abbott India and former Board member of Abbott Healthcare.`
                },
                {
                    title: `He was responsible for turnaround of General Care and Vaccines Business Unit at Abbott India.`
                },
                {
                    title: `He has worked at Torrent Pharma, Sun Pharma and CIPLA in the past.`
                }
            ],

        },
        {
            name: 'Anindya Ghose',
            role: 'Professor (NYU Stern School of Business)',
            imgUrlDefault: AnindyaBlackThemeImage,
            imgUrlHover: AnindyaImage,
            linkdinUrl: "https://www.linkedin.com/in/anindya-ghose/",
            aboutUs: [
                {
                    title: `Heinz Reihl Chair Professor of Business at NYU Stern School of Business`
                },
                {
                    title: `Director of the Masters of Science in Business Analytics program at NYU Stern.`
                },
                {
                    title: `Visiting Professor at the Wharton School of Business.`
                },
                {
                    title: `Worked on reputation and rating systems, digital marketing, sponsored search advertising, wearable technologies, mobile commerce, mobile advertising, crowdfunding, internet markets and online platforms`
                },
                {
                    title: `Teaches courses in business analytics at the executive level in the US and in various parts of the world including India, China, South Korea and Europe.`
                },
                {
                    title: `He is a 2014 “Top 40 Under 40 Professor”, a 2015 INFORMS ISS Distinguished Fellow and a 2017 Thinkers50 award recipient.`
                },
                {
                    title: `His book TAP is a double winner in the 2018 Axiom Business Awards and won the Gold medal in the 'Business Technology' category and the Bronze medal in the 'Economics' category.`
                }
            ],
        },
        {
            name: 'Pankaj Jathar',
            role: 'Ecommerce/Marketplace/Consumer',
            imgUrlDefault: PankajJatharBlackTheme,
            imgUrlHover: PankajImage,
            linkdinUrl: "https://www.linkedin.com/in/jathar/",
            aboutUs: [
                {
                    title: `Pankaj Jathar brings over 25 years of unparalleled experience in e-commerce and technology leadership, including transformative roles as Country Head & VP at Etsy and CEO at Prione (JV between Amazon and Catamaran). `
                },
                {
                    title: `With a deep understanding of the e-commerce value chain from his tenure at Amazon and significant achievements in empowering SMEs in India, Pankaj is uniquely positioned to advise on innovative growth strategies and operational excellence. `
                },
                {
                    title: `His global experience and ability to solve complex business challenges make him an invaluable asset to our fund, guiding startups towards scalable success and market leadership.`
                },
            ]
        },
        {
            name: 'Suryakumar PVS',
            role: 'Former Dy Managing Director, NABARD',
            imgUrlDefault: SuryakumarBlackTheme,
            imgUrlHover: SuryakumarImage,
            linkdinUrl: "https://www.linkedin.com/in/suryakumar-p-v-s-03353052/",
            aboutUs: [
                {
                    title: `Former Deputy MD of National Bank for Agriculture & Rural Development (NABARD).`
                },
                {
                    title: `Former Chairman of NABSAMRUDDHI, an impact focused NBFC`
                },

            ],
        },
        {
            name: 'Arun Agrawal',
            role: 'Financial Services',
            imgUrlDefault: ArunAgrawalBlackTheme,
            imgUrlHover: ArunAgrawal,
            linkdinUrl: "https://www.linkedin.com/in/arun-agrawal-4294234/",
            aboutUs: [
                {
                    title: `Former Country Head – Institutional and Government Banking at YES Bank.`
                },
                {
                    title: `Arun Agrawal is a seasoned banker with over 3 decades of financial services experience. Under Arun’s leadership, YES Bank established its FI and International banking businesses making YES a leading player in cross-border remittances and capital markets.`
                },
                {
                    title: `Arun also helped establish YES as a leader in the ESG space through its engagements with DFIs and Multilaterals.`
                },

            ],
        },
        {
            name: 'Prashant Tandon',
            role: 'Fund Raising',
            imgUrlDefault: PrashantTandonBlackTheme,
            imgUrlHover: PrashantTandon,
            aboutUs: [

                {
                    title: `Prashant is the founder of Hyphen Consulting & DreamCatcher Advisory Services which operate in private equity, wealth management, strategy & financial planning.`
                },
                {
                    title: `Previously, he has worked at Kotak Bank for nearly 14 years, in the capacity of  Executive Vice President - Partnerships and Alliances for Kotak Bank. Prashant was part of the core team instrumental in setting up the Kotak Cards business from scratch. Earlier, Prashant was working as General Manager - E Commerce at Times Internet limited and was responsible for setting up and launching several eCommerce initiatives and revenue generation business models.`
                },
                {
                    title: `Prashant's total corporate career spans nearly 24 years with a strong mix of media marketing, internet businesses and financial services.`
                },

            ],
        },
    ];









    return (
        <>
            <div className='section_four'></div>
            <section className="section section-sm section-fluid teams_section px-3 pb-5 pt-4 " id="team">
                <div className='mb-5'>
                    <div className='text-center heading_style my-4 pb-3'>
                        <span data-aos="fade-down">Our Team</span>
                    </div>
                    <div className='px-2'>
                        <div className='mt-3 mb-4 pb-2'>
                            <div className='ps-0 ps-sm-3 ms-0 ms-sm-2'>
                                <span className='title_style' data-aos-duration="700" data-aos="fade-right">Founder & Co-founder</span>
                            </div>
                        </div>
                        <div className="row px-0 px-md-4">
                            {founder?.map((item, index) => (
                                <div className="col-md-6 col-sm-6 col-12 mb-4  mb-md-0" key={index}>
                                    <div className="founderCard bg-light" data-aos="zoom-in">
                                        <div>
                                            <div className='image_div '>
                                                <img src={item?.imgUrlDefault} alt={item?.imgUrlDefault} className="default" />
                                                <img src={item?.imgUrlHover} alt={item?.imgUrlHover} className="on_hover" />
                                            </div>
                                            <div className='mt-4 d-flex justify-content-between align-items-center'>
                                                <div>
                                                    <h4 className="founder_heading" data-aos="fade-right">{item?.name}</h4>
                                                    <p className="founder_title" data-aos="fade-right">{item?.role}</p>
                                                </div>
                                                <div className='mt-3'>
                                                    <button className='text-white more_info d-flex justify-content-center align-items-center'
                                                        onClick={() => setShowModal((prev) => ({ ...prev, state: true, data: item, team: "founder" }))}
                                                    >
                                                        <MdAdd />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div>
                        <div className='mt-5 mb-4 pb-2'>
                            <div className='ps-0 ps-sm-4 ms-0 ms-sm-2'>
                                <span className='title_style' data-aos="fade-right">Board of Advisors</span>
                            </div>
                        </div>
                        <div className='d-none d-lg-block'>
                            <div className="d-flex justify-content-center ps-3">

                                <Slider {...setting}>
                                    {advisor?.map((item, index) => (
                                        <div
                                            data-aos="zoom-in"
                                            key={index}
                                        >
                                            <article className="team-classic">
                                                <div className="team-classic-figure" >
                                                    <img src={item?.imgUrlDefault} alt={item?.imgUrlDefault} className="default" />
                                                    <img src={item?.imgUrlHover} alt={item?.imgUrlHover} className="on_hover" />
                                                </div>
                                                <div className="team-classic-caption d-flex justify-content-between" data-aos="fade-right">
                                                    <div className='team-classic-caption-left'>
                                                        <h4 className="team-classic-name">
                                                            <a onClick={() => setShowModal((prev) => ({ ...prev, state: true, data: item }))}>{item?.name}</a>
                                                        </h4>
                                                        <p className="team-classic-status">{item?.role}</p>
                                                    </div>
                                                    <div className='team-classic-caption-right d-flex justify-content-center align-items-center'>
                                                        <button className='more_info more_info_hover  d-flex justify-content-center align-items-center'
                                                            onClick={() => setShowModal((prev) => ({ ...prev, state: true, data: item, team: "advisor" }))}
                                                        >
                                                            <MdAdd />
                                                        </button>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                    ))}
                                </Slider>

                            </div>
                        </div>
                        <div className='d-lg-none d-block'>
                            <div className='row'>
                                {advisor?.map((item, index) => (
                                    <div
                                        className="col-md-3 col-sm-6 col-12 mb-4"
                                        data-wow-delay=".1s"
                                        key={index}
                                    >
                                        <article className="team-classic">
                                            <div className="team-classic-figure" >
                                                <img src={item?.imgUrlDefault} alt={item?.imgUrlDefault} className="default" />
                                                <img src={item?.imgUrlHover} alt={item?.imgUrlHover} className="on_hover" />
                                            </div>
                                            <div className="team-classic-caption d-flex justify-content-between" data-aos="fade-right">
                                                <div className='team-classic-caption-left'>
                                                    <h4 className="team-classic-name">
                                                        <a onClick={() => setShowModal((prev) => ({ ...prev, state: true, data: item }))}>{item?.name}</a>
                                                    </h4>
                                                    <p className="team-classic-status">{item?.role}</p>
                                                </div>
                                                <div className='team-classic-caption-right d-flex justify-content-center align-items-center'>
                                                    <button className='more_info more_info_hover  d-flex justify-content-center align-items-center'
                                                        onClick={() => setShowModal((prev) => ({ ...prev, state: true, data: item, team: "advisor" }))}
                                                    >
                                                        <MdAdd />
                                                    </button>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>


                </div>
                <div>
                    <Modal
                        show={showModal.state} onHide={() => setShowModal({ state: false, data: "" })}
                        size={"lg"}
                        centered
                        className='rounded rounded-2'
                    >
                        <div className="modal_height rounded rounded-2">
                            <div
                                className="py-1 px-3 d-flex justify-content-between align-items-center mb-1 bg-dark"
                                style={{ borderBottom: "0px solid transparent" }}
                            >
                                <span className="title_style fs-5 text-warning border-bottom-0">
                                    {showModal?.team === "advisor" && "Board of Advisors"}
                                    {showModal?.team === "founder" && "Founder & Co-founder"}
                                </span>
                                <span
                                    className="close_modal fs-3 text-white"
                                    onClick={() => setShowModal(false)}
                                >
                                    &times;
                                </span>
                            </div>
                            <div className='modal_data px-4 pt-3 pb-3 '>
                                <div className='modal_inner gap-4 px-3 pb-4'>
                                    <div>
                                        <div className='modal_image d-flex align-items-center gap-4 mb-4'>
                                            <div>
                                                <img src={showModal?.data?.imgUrlHover} alt="" />
                                            </div>
                                            <div className='w-100'>
                                                <div className='w-100 d-flex justify-content-between align-items-center'>
                                                    <div>
                                                        <span>{showModal?.data?.name}</span>
                                                    </div>
                                                    {showModal?.data?.linkdinUrl &&
                                                        <div>
                                                            <a href={showModal?.data?.linkdinUrl} target="_blank">
                                                                <img src={linkdin} alt="LinkedIn" className='linkdin_style' />
                                                            </a>

                                                        </div>
                                                    }
                                                </div>
                                                <p className='mb-1'>
                                                    {showModal?.data?.role}
                                                </p>


                                            </div>
                                        </div>
                                    </div>
                                    <div className='modal_content'>
                                        <ul>

                                            {showModal?.data?.aboutUs?.map((key, index) => (
                                                <li>{key?.title}</li>
                                            ))}

                                        </ul>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            </section>

        </>

    )
}

export default Teams
